module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lakewood Dental | Serving Lakewood, CA Area for over 30 years","short_name":"Lakewood Dental","start_url":"/","description":"Lakewood Dental has been serving the Lakewood, CA area for over 30 years.","background_color":"#4294C2","theme_color":"#1F3B53","display":"minimal-ui","icon":"src/images/favion.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"54a5b9ea3a70522fa6f8c0a3bac5bf62"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
