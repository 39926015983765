exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dental-advice-cracked-teeth-js": () => import("./../../../src/pages/dental-advice/cracked-teeth.js" /* webpackChunkName: "component---src-pages-dental-advice-cracked-teeth-js" */),
  "component---src-pages-dental-advice-crowns-js": () => import("./../../../src/pages/dental-advice/crowns.js" /* webpackChunkName: "component---src-pages-dental-advice-crowns-js" */),
  "component---src-pages-dental-advice-importance-of-flossing-js": () => import("./../../../src/pages/dental-advice/importance-of-flossing.js" /* webpackChunkName: "component---src-pages-dental-advice-importance-of-flossing-js" */),
  "component---src-pages-dental-advice-js": () => import("./../../../src/pages/dental-advice.js" /* webpackChunkName: "component---src-pages-dental-advice-js" */),
  "component---src-pages-fees-and-insurance-js": () => import("./../../../src/pages/fees-and-insurance.js" /* webpackChunkName: "component---src-pages-fees-and-insurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-patients-js": () => import("./../../../src/pages/new-patients.js" /* webpackChunkName: "component---src-pages-new-patients-js" */),
  "component---src-pages-services-cosmetics-js": () => import("./../../../src/pages/services/cosmetics.js" /* webpackChunkName: "component---src-pages-services-cosmetics-js" */),
  "component---src-pages-services-dental-implants-js": () => import("./../../../src/pages/services/dental-implants.js" /* webpackChunkName: "component---src-pages-services-dental-implants-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-prevention-js": () => import("./../../../src/pages/services/prevention.js" /* webpackChunkName: "component---src-pages-services-prevention-js" */),
  "component---src-pages-services-restoration-js": () => import("./../../../src/pages/services/restoration.js" /* webpackChunkName: "component---src-pages-services-restoration-js" */),
  "component---src-pages-services-teeth-whitening-js": () => import("./../../../src/pages/services/teeth-whitening.js" /* webpackChunkName: "component---src-pages-services-teeth-whitening-js" */)
}

